import { GetterTree }          from 'vuex';
import { LocationForm, State } from '@/views/administration/views/administration-locations/administration-locations.types'
import { RootState }           from '@/store/store.types';

export const getters: GetterTree<State, RootState> = {
  selectedLocation(state):LocationForm{
    if(state.selectedLocation) {
      const locationGroups = state.selectedLocation.groups.map(location => location.uuid)
      return {
        ...state.selectedLocation, groups: locationGroups
      }
    }
    return {
      name: '',
      number: '',
      groups: [],
    }
  },
  locationsToReplace(state) {
    return state.locationsToReplace.map(location => ({ label: location.name, uuid: location.uuid }))
  },
}
