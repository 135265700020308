import { ActionTree }                                                                          from 'vuex';
import { createEntity, deleteSingleEntity, getEntitiesToReplace, getSingleEntity, getTableData, replaceEntity, updateSingleEntity } from '@/views/administration/administration.service';
import { Notification }                                                                        from '@/components/notification/notification';
import { RootState }                                                                           from '@/store/store.types';
import { AdministrationParams, SidePanelState } from '@/types';
import { LocationPayload, State }                                                              from '@/views/administration/views/administration-locations/administration-locations.types';
import { AdministrationEndpoints, AdministrationPagination, DeleteEntityResponse } from '@/views/administration/administration.types';
import i18n                                                                                    from '@/i18n';
import { GLOBAL }                                                                              from '@/helpers/variables';

export const actions: ActionTree<State, RootState> = {
  async getAllLocations(
    { commit, state, rootState },
  ): Promise<void> {
    try {
      const { pageSize, currentPage } = rootState.pagination[AdministrationPagination.LOCATIONS]

      const params: Partial<AdministrationParams> = state.tableParams;

      const allParams: Partial<AdministrationParams> = {
        ...params,
        page: currentPage,
        size: pageSize,
      };

      const { data } = await getTableData(AdministrationEndpoints.LOCATIONS, allParams);
      commit('setAllLocations', data.content);
      commit('updatePaginationElements', {
        totalElements: data.totalElements,
        name: AdministrationPagination.LOCATIONS,
      },
      { root: true }
      )

    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getSelectedLocation({ commit }, locationUuid: string): Promise<void> {
    try {
      const { data } = await getSingleEntity(AdministrationEndpoints.LOCATIONS, locationUuid);
      commit('setSelectedLocation', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async deleteLocation({ dispatch }, locationUuid: string): Promise<void> {
    try {
      await deleteSingleEntity(AdministrationEndpoints.LOCATIONS, locationUuid);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('locations.notifications.delete')
      );
    } catch (e) {
      e.data.errorCode === DeleteEntityResponse.DELETE_RESTRICT
        ? dispatch('getLocationsToReplace', locationUuid)
        : Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${GLOBAL}.errors.${e.data}`)
        );
      throw e.data;
    }
  },

  async createNewLocation(context, payload: LocationPayload): Promise<void> {
    try {
      await createEntity(AdministrationEndpoints.LOCATIONS, payload);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('locations.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async editLocationEntity(
    context,
    payload: { uuid: string; data: LocationPayload}
  ): Promise<void> {
    try {
      await updateSingleEntity(AdministrationEndpoints.LOCATIONS, payload.uuid, payload.data);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('locations.notifications.success')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getLocationGroups({ commit }) {
    try {
      const { data } = await getTableData(AdministrationEndpoints.LOCATIONS_GROUPS, {});
      commit('setLocationGroups', data.content)
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getLocationsToReplace({ commit }, locationUuid: string) {
    try {
      const { data } = await getEntitiesToReplace(AdministrationEndpoints.LOCATIONS,locationUuid)
      commit('setLocationsToReplace', data)
      commit('setSidePanelState', SidePanelState.DELETE_ENTITY)
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async replaceLocation(context, { locationUuid, replaceToLocationUuid }: {locationUuid: string, replaceToLocationUuid: string}) {
    try {
      await replaceEntity(AdministrationEndpoints.LOCATIONS, locationUuid, replaceToLocationUuid)
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('locations.notifications.replace')
      );
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  }
};
