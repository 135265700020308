import { State }       from '@/views/administration/views/administration-locations/administration-locations.types';
import { columnTypes } from '@/components/common/table/table.types';
import { SidePanelState } from '@/types';

export const state: State = {
  title: 'locations',
  allLocations: [],
  locationsToReplace: [],
  selectedLocation: null,
  sidePanelState: SidePanelState.EDIT_ENTITY,
  tableParams: {
    searchQuery: ''
  },
  columns: [
    {
      name: 'name',
      type: columnTypes.TEXT
    },
    {
      name: 'number',
      type: columnTypes.TEXT
    },
    {
      name: 'groups',
      type: columnTypes.TAGS
    }
  ],
  locationGroups: [],
  filters: []
}