
import { computed, ComputedRef, defineComponent, PropType, reactive } from 'vue';
import useVuelidate, { ValidationArgs } from '@vuelidate/core/';
import { required } from '@vuelidate/validators';
import { useGlobalTranslations, useTranslations } from '@/hooks/use-translations';
import { useGlobals } from '@/hooks/use-globals';
import cFormInput from '@/components/common/form/form-input/form-input.component.vue';
import cFormTextarea from '@/components/common/form/form-textarea/form-textarea.component.vue';
import {
  Location,
  LocationForm,
  LocationFormRules
} from '@/views/administration/views/administration-locations/administration-locations.types';
import CAdministrationFormButtons
  from '@/views/administration/components/administration-form-buttons/administration-form-buttons.component.vue';
import { FormMode } from '@/views/administration/administration.types';
import CFormCheckboxGroup from '@/components/common/form/form-checkbox-group/form-checkbox-group.component.vue';
import { Notification } from '@/components/notification/notification';
import i18n from '@/i18n';
import { GLOBAL } from '@/helpers/variables';

export default defineComponent({
  name: 'CFormLocations',
  components: {
    CFormCheckboxGroup,
    CAdministrationFormButtons,
    cFormTextarea,
    cFormInput,
  },
  props: {
    formMode: {
      type: String as PropType<FormMode>,
      default: FormMode.ADD,
    },
  },
  emits: ['closePanel', 'close-panel'],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const locationData: ComputedRef<LocationForm> = computed(() => store.getters['views/locations/selectedLocation']);
    const locationGroups: ComputedRef<Location> = computed(() => store.state.views.locations.locationGroups);
    const state: LocationForm = reactive({ ...locationData.value });
    const rules: ComputedRef<LocationFormRules> = computed(() => {
      return {
        name: { required },
        number: { required },
        groups: { required },
      };
    });
    const resetForm = (): void => {
      state.groups = [];
      state.name = '';
      state.number = '';
      state.description = '';
      v$.value.$reset();
    };

    const v$ = useVuelidate((rules as unknown) as ValidationArgs, state as any);
    const handleSubmit = async (closePanel: boolean): Promise<void> => {
      v$.value.$touch();
      if (!v$.value.$error) {
        try {
          if (props.formMode === FormMode.ADD) {
            await store.dispatch('views/locations/createNewLocation', state);
          } else if (props.formMode === FormMode.EDIT) {
            await store.dispatch('views/locations/editLocationEntity', { uuid: locationData.value.uuid, data: state });
          }
          resetForm();
          await store.dispatch('views/locations/getAllLocations');
          if (closePanel) {
            emit('closePanel');
          }
        } catch (e) {
          throw Error(e);
        }
      } else {
        Notification.warning(
          i18n.global.t(`${GLOBAL}.warning.warning`),
          i18n.global.t(`${GLOBAL}.warning.validation`),
        );
      }
    };

    const removeLocation = async (): Promise<void> => {
      await store.dispatch('views/locations/deleteLocation', locationData.value.uuid);
      await store.dispatch('views/locations/getAllLocations');
      emit('closePanel');
    };

    return {
      state,
      v$,
      useTranslations,
      useGlobalTranslations,
      handleSubmit,
      locationGroups,
      removeLocation,
      FormMode,
    };
  },
});
