import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "min-h-25vh" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_administration_template = _resolveComponent("c-administration-template")
  const _component_c_form_locations = _resolveComponent("c-form-locations")
  const _component_c_delete_confirmation = _resolveComponent("c-delete-confirmation")
  const _component_c_side_panel = _resolveComponent("c-side-panel")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_c_administration_template, {
      "button-label": _ctx.useGlobalTranslations('button.addNewLocation'),
      columns: _ctx.columns,
      "pagination-name": _ctx.AdministrationPagination.LOCATIONS,
      params: _ctx.tableParams,
      rows: _ctx.tableRows,
      onAddNewElement: _ctx.openAddNewLocationForm,
      onEditElement: _ctx.openEditLocationForm,
      onParamsChange: _ctx.updateParams
    }, null, 8, ["button-label", "columns", "pagination-name", "params", "rows", "onAddNewElement", "onEditElement", "onParamsChange"]),
    _createVNode(_component_c_side_panel, {
      "is-visible": _ctx.isSidePanelOpen,
      title: _ctx.sidePanelTitle,
      onClosePanel: _ctx.closeSidePanel
    }, {
      default: _withCtx(() => [
        (_ctx.sidePanelState === _ctx.SidePanelState.EDIT_ENTITY)
          ? (_openBlock(), _createBlock(_component_c_form_locations, {
              key: 0,
              "form-mode": _ctx.locationFormMode,
              onClosePanel: _ctx.closeSidePanel
            }, null, 8, ["form-mode", "onClosePanel"]))
          : (_ctx.sidePanelState === _ctx.SidePanelState.DELETE_ENTITY)
            ? (_openBlock(), _createBlock(_component_c_delete_confirmation, {
                key: 1,
                "error-message": _ctx.useTranslations('deleteErrorMessage'),
                items: _ctx.locationsToReplace,
                "select-label": _ctx.useTranslations('labelLocations'),
                onBackToPreviousPanel: _ctx.goBackToEditForm,
                onChangeAssignedEntity: _ctx.replaceGroupAndDelete
              }, null, 8, ["error-message", "items", "select-label", "onBackToPreviousPanel", "onChangeAssignedEntity"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["is-visible", "title", "onClosePanel"])
  ]))
}