import { Module }    from 'vuex';
import { State }     from '@/views/administration/views/administration-locations/administration-locations.types'
import { RootState } from '@/store/store.types';
import { actions }   from '@/views/administration/views/administration-locations/store/administration-locations.actions';
import { mutations } from '@/views/administration/views/administration-locations/store/administration-locations.mutations';
import { getters }   from '@/views/administration/views/administration-locations/store/administration-locations.getters';
import { state }     from '@/views/administration/views/administration-locations/store/administration-locations.state';

const store: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations

};

export default store;
