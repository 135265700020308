
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue';
import { useTranslations, useGlobalTranslations }           from '@/hooks/use-translations';
import { useGlobals }                                       from '@/hooks/use-globals';
import { AdministrationParams, SearchbarSelectItem, SidePanelState } from '@/types';
import { TableColumn, TableRow }                            from '@/components/common/table/table.types';
import CSidePanel                                           from '@/components/common/side-panel/side-panel.component.vue';
import CFormLocations                                       from '@/views/administration/views/administration-locations/components/form-locations/form-locations.component.vue';
import cAdministrationTemplate                              from '@/views/administration/components/administration-template/administration-template.component.vue';
import { AdministrationPagination, FormMode }               from '@/views/administration/administration.types';
import CDeleteConfirmation
  from '@/views/administration/components/delete-confirmation/delete-confirmation.component.vue';
import { Group } from '@/views/administration/views/administration-groups/administration-groups.types';

export default defineComponent({
  name: 'VAdministrationLocations',
  components: { CDeleteConfirmation, CFormLocations, CSidePanel, cAdministrationTemplate },
  setup() {
    const { store, i18n } = useGlobals();
    const columns: ComputedRef<TableColumn[]> = computed(() => store.state.views.locations.columns);
    const tableParams: ComputedRef<AdministrationParams> = computed(() => store.state.views.locations.tableParams);
    const tableRows: ComputedRef<TableRow[]> = computed(() => store.state.views.locations.allLocations);
    const locationsToReplace: ComputedRef<SearchbarSelectItem[]> = computed(() => store.getters['views/locations/locationsToReplace'])
    const sidePanelTitle: Ref<string> = ref('');
    const isSidePanelOpen: Ref<boolean> = ref(false);
    const locationFormMode: Ref<FormMode> = ref(FormMode.ADD);
    const selectedLocation: ComputedRef<Group> = computed(() => store.state.views.locations.selectedLocation);
    const sidePanelState: ComputedRef<SidePanelState> = computed(() => store.state.views.locations.sidePanelState)

    store.commit('views/locations/setTableParams', []);
    store.dispatch('views/locations/getAllLocations');
    store.dispatch('views/locations/getLocationGroups');

    const closeSidePanel = (): void => {
      isSidePanelOpen.value = false;
      store.commit('views/locations/setSidePanelState', SidePanelState.EDIT_ENTITY)
      store.commit('views/locations/setSelectedLocation', null);
    };

    const openAddNewLocationForm = (): void => {
      sidePanelTitle.value = i18n.t('locations.sidePanel.addNewLocation');
      locationFormMode.value = FormMode.ADD;
      isSidePanelOpen.value = true;
    };

    const openEditLocationForm = async (uuid: string): Promise<void> => {
      closeSidePanel();
      isSidePanelOpen.value = false;
      await store.dispatch('views/locations/getSelectedLocation', uuid);
      locationFormMode.value = FormMode.EDIT;
      sidePanelTitle.value = i18n.t('locations.sidePanel.editLocation');
      isSidePanelOpen.value = true;
    };

    const updateParams = async (updatedParams: Partial<AdministrationParams>): Promise<void> => {
      store.commit('views/locations/setTableParams', updatedParams);
      await store.dispatch('views/locations/getAllLocations');
    };

    const goBackToEditForm = () => {
      store.commit('views/locations/setSidePanelState', SidePanelState.EDIT_ENTITY)
    }

    const replaceGroupAndDelete = async (locationUuidToReplace: string) => {
      await store.dispatch('views/locations/replaceLocation', { locationUuid: selectedLocation.value.uuid, replaceToLocationUuid: locationUuidToReplace })
      store.dispatch('views/locations/getAllLocations')
      closeSidePanel()
    }

    return {
      columns,
      tableRows,
      tableParams,
      isSidePanelOpen,
      sidePanelTitle,
      locationFormMode,
      sidePanelState,
      locationsToReplace,
      openAddNewLocationForm,
      openEditLocationForm,
      closeSidePanel,
      updateParams,
      useGlobalTranslations,
      useTranslations,
      goBackToEditForm,
      replaceGroupAndDelete,
      AdministrationPagination,
      SidePanelState,
    };
  },
});
