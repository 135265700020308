import { MutationTree } from 'vuex';
import {
  Location,
  LocationGroup,
  State,
} from '@/views/administration/views/administration-locations/administration-locations.types';
import { AdministrationParams, FilterTypes, SidePanelState } from '@/types';
import { columnTypes } from '@/components/common/table/table.types';
import { Group } from '@/views/administration/views/administration-groups/administration-groups.types';

export const mutations: MutationTree<State> = {
  setAllLocations(state, locationsData: Location[]): void {
    state.allLocations = locationsData;
  },
  setSelectedLocation(state, location: Location | null): void {
    state.selectedLocation = location;
  },
  setTableParams(state, params: AdministrationParams): void {
    state.tableParams = params;
  },
  setLocationGroups(state, locationsGroups: LocationGroup[]): void {
    state.columns = [
      {
        name: 'name',
        type: columnTypes.TEXT,
        filter: FilterTypes.SORT,
      },
      {
        name: 'number',
        type: columnTypes.TEXT,
        filter: FilterTypes.SORT,
      },
      {
        name: 'groups',
        type: columnTypes.TAGS,
        filter: FilterTypes.MULTI_SELECT,
        filterOptions: locationsGroups,
        selectedOptions: []
      },
    ];
    state.locationGroups = locationsGroups;
  },
  setSidePanelState(state, payload: SidePanelState): void {
    state.sidePanelState = payload
  },
  setLocationsToReplace(state, payload: Location[]): void {
    state.locationsToReplace = payload
  }
};